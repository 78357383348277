import { Controller } from "@hotwired/stimulus";
import moment from "moment"

export default class extends Controller {
  static targets = [
    "feesComparison"
  ];
  static values = { 
    url: String,
    id: String,
    yAxis: { type: Boolean, default: false },
    xAxis: { type: Boolean, default: false },
    lineWidth: { type: Number, default: 2 },
    margin: { type: Array, default: [0, 0, 4, 0] }
  };

  MARKER_STYLE = { marker: { fillColor:'black', radius: 8, lineColor: 'white', lineWidth: 3 } }

  connect() {

    this.request = new Request(this.urlValue)
    
    // Comment this out and uncomment line 14 for faster results.
    setTimeout(() => {
      this.fetchContent(this.request);
    }, 3000);
    // this.fetchContent(this.request);
  }
  
  fetchContent(request) {
    fetch(request)
    .then((response) => {
      if (response.status === 200) {
        response.text().then((text) => {
            let data = JSON.parse(text)
            this.displayGraph(data)
          });
        } else {
          this.renderContent("Could not load data");
        }
      })
      .catch((error) => {
        this.renderContent("Could not load data");
      });
  }

  displayGraph(data) {
    this.chart = Highcharts.chart(this.context.element, this.options({ data: data }))
  }

  options(data) {
    let fees_data = data.data.data_category
    let current = data.data.current
    let transformedData = this.transformData(current, fees_data, this.MARKER_STYLE)

    let options = {
      animation: false,
      credits: false,
      chart: {
        type: 'areaspline',
        height: 96,
        renderTo: this.idValue,
        margin: this.marginValue
      },
      title: { text: '' },
      tooltip: { enabled: false },
      plotOptions: {
        series: {
          marker: {
            radius: 0
          },
          color: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 1,
              y2: 0
            },
            stops: [
              [0, '#34D399'],
              [0.5, '#FBBF24'],
              [1, '#DC2626']
            ]
          },
        }
      },
      xAxis: {
        visible: this.xAxisValue,
        title: { text: 'Frais' },
        tickInterval: 0.2,
        labels: {
          format: '{value}%'
        }
      },
      yAxis: {
        visible: this.yAxisValue,
        title: { text: 'Nb de fonds' },
        tickInterval: 5
      },
      series: [{
        showInLegend: false,
        data: transformedData,
        lineWidth: this.lineWidthValue,
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 1,
            y2: 0
          },
          stops: [
            [0, 'rgba(52, 211, 153, 0.4)'],
            [0.5, 'rgba(251, 191, 36, 0.4)'],
            [1, 'rgba(220, 38, 38, 0.4)']
          ]
        },
      }]
    };
    return options
  }

  transformData(shareFee, data, style) {
    
    if (shareFee) {
      var minRange = [10, 0.0]
      var minDiff = 10.0
      for (var i = 0; i < data.length; i++) {
        if (Math.abs((shareFee - data[i][0])) < minDiff) {
          minRange = data[i]
          minDiff = Math.abs((shareFee - data[i][0]))
        }
      }
    }
    var items = data.map(item => {
      var point = { x: item[0], y: item[1] }
      if (item == minRange) {
        return Object.assign(style, point)
      }
      else {
        return point
      }
    })
    return items
  }

  renderContent(content) {
    this.sharePerformanceTarget.innerHTML = content
    this.dispatchEvent("lazy_load:complete")
  }

  dispatchEvent(eventName) {
    const event = new Event(eventName);
    document.dispatchEvent(event);
  }

}
