import { Controller } from "stimulus"
import Fuse from 'fuse.js'

export default class extends Controller {
  static targets = [
    'contract',
    'insurer'
  ]

  connect() {
    console.log("connected")
  }

  search(event) {
    const pattern = event.currentTarget.value

    if (pattern == "") {
      this.showAll()
      return
    }
    const options = {
      threshold: 0.2,
      keys: [
        "name"
      ]
    }
    const list = this.contractTargets.map(target => Object.assign({}, target.dataset))
    const fuse = new Fuse(list, options)
    const contracts = fuse.search(pattern).map(response => response.item)

    console.log(contracts)

    this.hideAll()

    contracts.forEach(contract => {
      document.querySelector('#contract-' + contract.id).classList.remove('hidden')
      document.querySelector('#insurer-' + contract.insurerId).classList.remove('hidden')
    })
  }

  hideAll() {
    this.insurerTargets.forEach(target => target.classList.add('hidden'))
    this.contractTargets.forEach(target => target.classList.add('hidden'))
  }

  showAll() {
    this.insurerTargets.forEach(target => target.classList.remove('hidden'))
    this.contractTargets.forEach(target => target.classList.remove('hidden'))
  }
}