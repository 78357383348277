import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
  }
  toggle(event) {
    event.preventDefault();
    let idToShow = event.currentTarget.dataset.collapseId;
    let collapse = document.getElementById(`collapse_${idToShow}`);
    collapse.classList.toggle("hidden");
  }
}