import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 
    "links", 
    "templateShareholder",
    "linksIntel",
    "templateIntel",
    "linksAum",
    "templateAum",
    "linksAumAssetAllocation",
    "templateAumAssetAllocation",
    "linksAumProducts",
    "templateAumProducts",
    "linksAumClients",
    "templateAumClients",
    "personCount",
    "totalPersonCount"
  ]

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields"
  }

  add_shareholder(event) {
    event.preventDefault()
    var content = this.templateShareholderTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
  }

  add_financial_intel(event) {
    event.preventDefault()
    var content = this.templateIntelTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksIntelTarget.insertAdjacentHTML('beforebegin', content)
  }

  add_aum(event) {
    event.preventDefault()
    var content = this.templateAumTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksAumTarget.insertAdjacentHTML('beforebegin', content)
  }

  add_aum_asset_allocation(event) {
    event.preventDefault()
    var content = this.templateAumAssetAllocationTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksAumAssetAllocationTarget.insertAdjacentHTML('beforebegin', content)
  }

  add_aum_products(event) {
    event.preventDefault()
    var content = this.templateAumProductsTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksAumProductsTarget.insertAdjacentHTML('beforebegin', content)
  }

  add_aum_clients(event) {
    event.preventDefault()
    var content = this.templateAumClientsTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksAumClientsTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove_shareholder_with_confirm(event) {
    event.preventDefault()
    if (confirm(event.currentTarget.dataset.confirmation)) {
      this.remove_shareholder(event)
    }
  }

  remove_item(event) {
    event.preventDefault()
    let wrapper = event.target.closest("." + this.wrapperClass)
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.classList.add('d-none')
    }
  }

  processTotalField() {
    let total = this.personCountTargets.reduce(function(sum, input) {
      return sum + parseFloat(input.value || 0) 
    }, 0.0)

    total = parseFloat(total.toPrecision(9))

    this.totalPersonCountTarget.innerHTML = this.totalHtml(total)
    // this.totalNumberTarget.value = this.totalNumber(total)
  }

  totalHtml(total) {
    return `
      <p class='text-md font-medium text-oxford-700 border-t-2 border-oxford-700 pt-2 mt-4'>
        ${isNaN(total) ? "-" : total.toFixed(0) + " personnes"}
      </p>
    `
  }

  totalNumber(total) {
    return isNaN(total) ? "-" : total.toFixed(0)
  }

}
