import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 
    "personNumber",
    "totalPersons",
    "totalNumber"
  ]

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields"
  }

  processTotalField() {
    let total = this.personNumberTargets.reduce(function(sum, input) {
      return sum + parseFloat(input.value || 0) 
    }, 0.0)

    total = parseFloat(total.toPrecision(9))

    this.totalPersonsTarget.innerHTML = this.totalHtml(total)
    this.totalNumberTarget.value = this.totalNumber(total)
  }

  totalHtml(total) {
    return `
      <p class='mt-4 block string text-sm font-medium text-gray-700'>
        Total : ${isNaN(total) ? "-" : total.toFixed(0) + " personnes"}
      </p>
    `
  }

  totalNumber(total) {
    return isNaN(total) ? "-" : total.toFixed(0)
  }
}
