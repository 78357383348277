import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'checkbox',
    'fund',
    'share',
    'current',
    'eraseFilters',
    'contractsHeader',
    'contractsCountHeader'
  ]

  connect() {
    this.resetDisplay()
  }

  toggleInsurer(event) {
    const insurer = event.currentTarget
    if (insurer.checked) {
      Array.from(insurer.parentElement.querySelectorAll('[data-contracts] input[type="checkbox"]'))
        .filter(contract => !contract.parentNode.classList.contains('hidden'))
        .forEach(contract => {
          contract.checked = true
        })
    } else {
      Array.from(insurer.parentElement.querySelectorAll('[data-contracts] input[type="checkbox"]'))
        .filter(contract => !contract.parentNode.classList.contains('hidden'))
        .forEach(contract => {
          contract.checked = false
        })
    }
  }

  toggleContract(event) {
    const contractId = event.currentTarget.value
    this.determineInsurerChecked(event.currentTarget)
    if (event.currentTarget.checked == true) {
      event.currentTarget.parentElement.querySelector(".insurer-checkbox").checked = true
    } else {
      event.currentTarget.parentElement.querySelector(".insurer-checkbox").checked = false
    }
  }

  determineInsurerChecked(contract) {
    const isChecked = (element) => element.checked == true
    const isUnchecked = (element) => element.checked == false
    const contracts = Array.from(contract.closest('[data-contracts]').querySelectorAll('input[type="checkbox"]'))
    const insurer = document.querySelector('[data-insurer="' + contract.dataset.insurerId + '"]')

    if (contracts.every(isChecked)) {
      insurer.checked = true
      insurer.indeterminate = false
    } else if (contracts.every(isUnchecked)) {
      insurer.checked = false
      insurer.indeterminate = false
    } else {
      insurer.indeterminate = true
    }
  }

  
  uncheckAll() {
    this.checkboxTargets.forEach(checkbox => checkbox.checked = false)
  }

  resetDisplay() {
    this.fundTargets.forEach(fundTarget => fundTarget.classList.remove('hidden'))
    this.shareTargets.forEach(fundTarget => fundTarget.classList.add('hidden'))
  }

  resetCurrent() {
    this.currentTarget.innerHTML = ""
  }

  reset(event) {
    this.uncheckAll()
    this.resetCurrent()
    document.getElementById("eos-list-filter-base").classList.remove("hidden")
    document.getElementById("erase-filter-button").classList.add("hidden")
  }
}