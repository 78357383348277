import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'html',
    'source',
    'success'
  ]

  connect() {
  }

  copy() {
    this.sourceTarget.value = this.htmlTarget.outerHTML
    this.sourceTarget.select()
    document.execCommand("copy")

    this.successTarget.classList.remove('d-none')
    this.successTarget.classList.remove('opacity-0')
    this.successTarget.classList.add('opacity-100')
    this.successTarget.classList.add('d-flex')
    setTimeout(() => {
      this.successTarget.classList.add('opacity-0')
      this.successTarget.classList.remove('opacity-100')
    }, 500)

  }

}

