import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["element", "select"]

  connect() {

  }

  changed(event) {
    if (this.hasElementTarget) {
      this.elementTargets.forEach(element => 
        this.toggle(element, element.dataset.values, event.target.value)
      );
    }
  }
  
  toggle(element, values, value) {
    if (element && values) {

      for (let _value of values.split(",")) {
        if (_value === value) {
          element.classList.remove('hidden')
        } else {
          element.classList.add('hidden')
        }
      }

    }
  }

}