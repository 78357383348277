import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'input',
    'preview',
    'hint',
    'remove',
    'keep'
  ]

  preview() {
    this.readURL(this)
    this.hintTarget.classList.add('d-none')
    this.previewTarget.classList.remove('d-none')
  }

  readURL(e) {
    let that = this
    if (this.inputTarget.files && this.inputTarget.files[0]) {
      var reader = new FileReader()
      reader.onload = function (e) {
        if (e.target.result != undefined) {
          that.previewTarget.style.backgroundImage = `url('${e.target.result}')`
          that.previewTarget.classList.remove('d-none')
          that.removeTarget.classList.remove('d-none')
          that.hintTarget.classList.add('d-none')
        } else {
          that.previewTarget.classList.add('d-none')
          that.removeTarget.classList.add('d-none')
          that.hintTarget.classList.remove('d-none')
        }
      }

      var file = reader.readAsDataURL(this.inputTarget.files[0])
    }
  }

  remove() {
    this.inputTarget.value = null
    this.previewTarget.style.backgroundImage = "none"
    this.previewTarget.classList.add('d-none')
    this.removeTarget.classList.add('d-none')
    this.hintTarget.classList.remove('d-none')
    this.keepTarget.value = "0"
  }
}