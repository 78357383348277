import { Controller } from "@hotwired/stimulus";
import moment from "moment"

export default class extends Controller {
  static targets = [
    "shareHistory"
  ];
  static values = { url: String };

  connect() {
    this.request = new Request(this.urlValue)
    // Comment this out and uncomment line 14 for faster results.
    setTimeout(() => {
      this.fetchContent(this.request);
    }, 3000);
    // this.fetchContent(this.request);
  }
  
  fetchContent(request) {
    fetch(request)
    .then((response) => {
      if (response.status === 200) {
        response.text().then((text) => {
            let data = JSON.parse(text)
            this.displayGraph(data)
          });
        } else {
          this.renderContent("Could not load data");
        }
      })
      .catch((error) => {
        this.renderContent("Could not load data");
      });
  }

  displayGraph(data) {
    this.chart = Highcharts.chart(this.context.element, this.options({ data: data }))
  }

  options(data) {
    let share_history = data.data.share_history
    let category_history = data.data.category_history

    let options = {
      credits: false,
      chart: {
        type: 'column',
        clip: false,
      },
        title: {
          text: null
        },
      xAxis: {
        categories: [
          '2016',
          '2017',
          '2018',
          '2019',
          '2020',
          '2021',
          '2022'
        ]
      },
      yAxis: {
        title: {
          text: 'Performance'
        },
        labels: {
          format: '{value}%'
        }
      },
      tooltip: {
          headerFormat: '<span class="text-sm">{point.key}</span><table>',
          pointFormat: '<tr><td class="pr-2 text-sm" style="color:{series.color};">{series.name}: </td>' +
              '<td class="text-sm" style="padding:0"><b>{point.y:.1f} %</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
            pointWidth: 20,
            borderWidth: 0
          }
      },
      series: [{
          showInLegend: false,
          name: 'Fonds',
          data: share_history,
          color: '#4ECF8C'
      }, {
          showInLegend: false,
          name: 'Catégorie',
          data: category_history,
          color: '#2619BE'
      }]
    }
    return options
  }

  renderContent(content) {
    this.sharePerformanceTarget.innerHTML = content
    this.dispatchEvent("lazy_load:complete")
  }

  dispatchEvent(eventName) {
    const event = new Event(eventName);
    document.dispatchEvent(event);
  }

}
