import { Controller } from "stimulus";

export default class extends Controller {
    initialize() {
    }
    open(event) {
      event.preventDefault();
      let idToShow = event.currentTarget.dataset.modalId;
      let modal = document.getElementById(`modal_${idToShow}`);
      modal.classList.remove("hidden");
    }
    close(event) {
      let idToHide = event.currentTarget.dataset.modalId;
      let modal = document.getElementById(`modal_${idToHide}`);
      modal.classList.add("hidden");
    }
}