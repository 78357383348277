import { Controller } from "@hotwired/stimulus";
import moment from "moment"

export default class extends Controller {
  static targets = [
    "sharePerformance"
  ];
  static values = { url: String };

  connect() {

    this.request = new Request(this.urlValue)
    
    // Comment this out and uncomment line 14 for faster results.
    setTimeout(() => {
      this.fetchContent(this.request);
    }, 3000);
    // this.fetchContent(this.request);
  }
  
  fetchContent(request) {
    fetch(request)
    .then((response) => {
      if (response.status === 200) {
        response.text().then((text) => {
            let data = JSON.parse(text)
            this.displayGraph(data)
          });
        } else {
          this.renderContent("Could not load data");
        }
      })
      .catch((error) => {
        this.renderContent("Could not load data");
      });
  }

  displayGraph(data) {
    Highcharts.setOptions({
      lang: {
        months: [
          'Janvier', 'Février', 'Mars', 'Avril',
          'Mai', 'Juin', 'Juillet', 'Août',
          'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ],
        shortMonths: [
          'Janv', 'Févr', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'
        ]
      }
    })
    
    this.chart = Highcharts.chart(this.context.element, this.options({ navs: data }))
  }

  options(data) {

    let min = this.minSeries(data) - 5
    let max = this.maxSeries(data) + 5

    let options = {
      credits: false,
      chart: {
        type: 'areaspline',
        spacingBottom: 0,
        spacingLeft: 0
      },
      margin: 0,
      title: {
        text: null
      },
      tooltip: {
        backgroundColor: '#FFFFFF',
        shadow: {
          width: 10,
          opacity: 0.02
        },
        style: {
          fontFamily: 'work sans',
        },
        borderWidth: 0,
        outside: true,
        crosshairs: [true,true],
        useHTML: true,
        formatter: function () {
          moment.locale('fr')
          return this.points.reduce(function (s, point) {
            return `
              ${s}
              <div class="flex justify-between mt-2 text-base">
                <div class="flex items-center">
                  <div class="mr-2 font-bold" style="width: 10px; height: 10px; background: ${point.series.color}"></div>
                  ${point.series.name} : 
                </div>
                <div class="pl-2 font-bold line-height-sm ${point.series.name == "Fonds" ? "text-pine-400" : "text-info-400" }">${point.y.toFixed(2)}</div>
              </div>
            `
          }, `<div class="text-center">${moment(this.x).format("D MMMM YYYY")}</div>`);
        },
        shared: true
      },
      colors:  ['#4ECF8C', '#2619BE'],
      plotOptions: {
        line: {
          marker: {
            enabled: false
          }
        }
      },
      xAxis: {
        visible: true,
        title: true,
        type: 'datetime',
        labels: {
          style: {
            color: "#8694A2",
            fontSize: 10
          }
        }
      },
      yAxis: {
        title: {
          text: 'Valeur',
          style: {
            color: "#8694A2"
          }
        },
        startOnTick: false,
        endOnTick: true,
        min: min,
        max: max,
        minTickInterval: 0.5,
        tickColor: "#D6E1EB",
        lineColor: "#D6E1EB",
        labels: {
          style: {
            fontSize: 10
          }
        },
      },
      legend: { enabled: false },

      series: []
    }

    if (data.navs.share_navs.length > 0) {
      options.series.push({
          name: 'Fonds',
          data: data.navs.share_navs,
          zIndex: 2,
          showInLegend: false,
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, 'rgba(78, 207, 140, 0.4)'],
              [0.5, 'rgba(78, 207, 140, 0.2)'],
              [1, 'rgba(78, 207, 140, 0.1)']
            ]
          }
      })
    }

    if (data.navs.category_navs) {
      options.series.push({
        name: "Categorie",
        data: data.navs.category_navs,
        showInLegend: false,
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [
            [0, 'rgba(75, 207, 221, 0)'],
            [0.5, 'rgba(75, 207, 221, 0)'],
            [1, 'rgba(75, 207, 221, 0)']
          ]
        }
      })
    }

    return options
  }

  minSeries(data) {
    var minArr = []
    minArr.push(this.minData(data.navs.share_navs))
    minArr.push(this.minData(data.navs.category_navs))

    return this.minData(minArr)[1]
  }

  maxSeries(data) {
    var maxArr = []
    maxArr.push(this.maxData(data.navs.share_navs))
    maxArr.push(this.maxData(data.navs.category_navs))

    return this.maxData(maxArr)[1]
  }

  minData(data) {
    var min = data[0];
    for (var i = 1; i < data.length; i++) {
      if (data[i] != null) {
        if (data[i][1] < min[1]) {
            min = data[i];
        }
      }
    }
    return min
  }

  maxData(data) {
    var max = data[0];
    for (var i = 1; i < data.length; i++) {
      if (data[i] != null) {
        if (data[i][1] > max[1]) {
            max = data[i];
        }
      }
    }
    return max
  }

  renderContent(content) {
    this.sharePerformanceTarget.innerHTML = content
    this.dispatchEvent("lazy_load:complete")
  }

  dispatchEvent(eventName) {
    const event = new Event(eventName);
    document.dispatchEvent(event);
  }

}
