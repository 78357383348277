require("trix")
require("@rails/actiontext")

import "./application.scss"

import Rails from '@rails/ujs'
import Turbolinks from "turbolinks"

Rails.start()
Turbolinks.start()

window.Rails = Rails

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

// import { Alert, Autosave, Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
import { Dropdown, Tabs, Toggle, Popover, Slideover} from "tailwindcss-stimulus-components"
application.register('dropdown', Dropdown)
application.register('tabs', Tabs)
application.register('toggle', Toggle)
application.register('popover', Popover)
application.register('slideover', Slideover)

// application.register('alert', Alert)
// application.register('autosave', Autosave)

document.addEventListener("turbolinks:load", function() {
  console.log("turbolinks:load");
});

document.addEventListener("lazy_load:complete", function() {
  console.log("lazy_load:complete");
});